@import 'src/style/_base.scss';

.bigcapital-datatable {
  display: block;

  .table {
    text-align: left;
    border-spacing: 0;
    min-width: 100%;
    display: block;

    .thead .thead-inner,
    .tbody .tbody-inner {
      min-width: fit-content;
    }

    .thead {
      overflow: hidden;

      .th {
        padding: 0.68rem 0.5rem;
        background: #f5f5f5;
        font-size: 14px;
        color: #4E5B6F;
        font-weight: 400;
        border-bottom: 1px solid #d2dde2;

        > div {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .sort-icon {
        width: 0;
        height: 0;
        position: relative;
        top: -2px;
        display: inline-block;
        margin-left: 5px;

        &--desc {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid #666;
        }

        &--asc {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 6px solid #666;
        }
      }
    }

    .tr {
      display: flex;
      flex: 1 0 auto;

      &:last-child {
        .td {
          border-bottom: 0;
        }
      }

      .bp4-context-menu-popover-target {
        z-index: 100;
      }

      .tr-context {
        display: flex;
        flex: 1 0 auto;
      }

      &:hover .td.clickable {
        cursor: pointer;
      }
    }

    .th,
    .td {
      box-sizing: border-box;
      flex: 0 0 auto;
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;
      margin: 0;
      padding: 0.5rem;

      .cell-inner {
        flex: 1 0 0;
      }

      &:last-child {
        border-right: 0;
      }

      .bp4-control {
        margin-bottom: 0;
      }

      .resizer {
        display: inline-block;
        background: transparent;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        touch-action: none;

        .inner-resizer {
          height: 100%;
          width: 1px;
          border-left: 1px solid #e6e6e6;
          margin: 0 auto;
        }

        &.isResizing .inner-resizer {
          background: #1183da;
        }
      }

      .bp4-control.bp4-checkbox .bp4-control-indicator {
        cursor: auto;

        &,
        &:hover {
          height: 15px;
          width: 15px;
        }
      }

      .bp4-control.bp4-checkbox {

        input:checked~.bp4-control-indicator,
        input:indeterminate~.bp4-control-indicator {
          border-color: #0052ff;
        }
      }

      .skeleton {
        animation: skeleton-fade-in 0.3s linear forwards,
          skeleton-glow 1s linear infinite alternate;

        animation-delay: 0s, 0.3s;
        height: 8px;
        opacity: 1;
        width: 65%;
        margin: 10px 0;
      }

      &.align-right {
        .skeleton {
          margin-left: auto;
        }
      }

      &.align-center {
        .skeleton {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .th {
      .skeleton {
        margin: 5px 0;
      }
    }

    .tbody {
      width: 100%;
      overflow: auto;

      .tbody-inner {
        >.loading {
          padding-top: 40px;
        }
      }

      .tr .td {
        border-bottom: 1px solid #e0e0e0;
        align-items: center;
        color: #101219;

        .placeholder {
          color: #a0a0a0;
        }

        .text-overview {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .bp4-form-group {
          width: 100%;
        }

        &.is-text-overview {
          .expend-padding {
            display: flex;
            width: 100%;
          }
        }
        &:focus {
          outline: 1px solid rgba(0, 82, 204, 0.7);
          outline-offset: -1px;
        }
      }

      .tr:hover .td {
        background: #f3f7fc;
      }

      .tr.is-context-menu-active .td {
        background: #f3fafc;
      }

      .td.actions .#{$ns}-button {
        background: #e6effb;
        border: 0;
        box-shadow: none;
        padding: 5px 15px;
        border-radius: 8px;

        &:hover,
        &:focus {
          background-color: #cfdcee;
        }

        svg {
          color: #425361;
        }

        .bp4-icon-more-h-16 {
          margin-top: 2px;
        }
      }

      .tr.no-results {
        .td {
          flex-direction: column;
          padding: 18px 20px;
          color: #777;
          align-items: center;
          font-size: 14px;
          border-bottom: 0;

          &:hover {
            background: transparent;
          }
        }
      }

      >.loading {
        padding-top: 50px;
      }
    }

    .tr .th,
    .tr .td {
      .expand-toggle {
        cursor: auto;
        display: inline-block;
        padding: 4px 8px;
        padding-left: 0;
        margin: auto 0;
        margin-left: 4px;
        vertical-align: top;

        .expand-arrow {
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 8px solid #acacac;
          display: block;
          transition: all 0.1s cubic-bezier(.4, 1, .75, .9);

          &.is-expanded {
            transform: rotate(90deg);
          }
        }

        &:hover {
          .expand-arrow {
            border-left-color: #7d8593;
          }
        }
      }
    }

    .tr-inner {
      display: flex;
      width: 100%;
    }

    &-size {

      &--medium {
        .tbody .tr {
          background-size: red;
          min-height: 46px;
        }
      }

      &--small {
        .tbody .tr {
          min-height: 40px;
        }
      }
    }
  }

  .no-results {
    color: #666;

    .td {
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
    }
  }

  &.has-sticky-header {
    .thead {
      .tr .th {
        position: sticky;
      }
    }
  }

  &.has-sticky {

    .thead,
    .tfoot {
      position: sticky;
      z-index: 1;
    }

    .thead {
      top: 0;
    }

    .tfoot {
      bottom: 0;
    }

    .tbody {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {}

    [data-sticky-first-right-td] {}
  }

  &.has-virtualized-rows {
    .tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

  .ReactVirtualized__Grid {
    will-change: auto !important;
  }
}

.table-constrant,
.table--constrant {
  .table {
    .thead {
      .tr:first-of-type .th {
        border-top: 1px solid #000000;
      }
    }

    .thead .th {
      background: #fff;
      color: #000;
      border-bottom: 1px solid #000000;
      padding: 0.5rem;
    }

    .tbody .tr .td {
      background: #fff;
      padding: 0.5rem 0.5rem;
      border-bottom: 0;
      color: #000;
    }
  }
}