@import './normalize.scss';
@import './_base.scss';

@import '@blueprintjs/core/src/blueprint.scss';
@import '@blueprintjs/datetime/src/blueprint-datetime.scss';
@import '@blueprintjs/popover2/src/blueprint-popover2.scss';

// Objects
@import 'objects/form';
@import 'objects/switch';
@import 'objects/typography';
@import 'objects/buttons';
@import 'objects/Bigcapital';

// Components
@import 'components/CustomScrollbar';
@import 'components/dragzone';
@import 'components/resizer';
@import 'components/CloudSpinner';
@import 'components/Alert';
@import 'components/Toast';
@import 'components/PageForm';
@import 'components/Tooltip';
@import 'components/Postbox';
@import 'components/Overlay';
@import 'components/Menu';
@import 'components/SidebarOverlay';
@import 'components/UniversalSearch';

// Pages
@import 'pages/view-form';
@import 'pages/register-organizaton';

// Views
@import 'views/filter-dropdown';

// fonts
@import 'pages/fonts';
@import "section";

.App {
  min-width: 960px;
}

// =======

body.hide-scrollbar .Pane2 {
  overflow: hidden;
}

.bp4-fill {

  .bp4-popover-wrapper,
  .bp4-popover-target {
    display: block;
    width: 100%;
  }

  .bp4-button {
    width: 100%;
    justify-content: start;
  }
}

.bp4-datepicker-caption .bp4-html-select::after {
  margin-right: 6px;
}

.bp4-select-popover .bp4-menu,
.bp4-multi-select-popover .bp4-menu {
  max-height: 300px;
  overflow: auto;
}

.bp4-form-group .bp4-label {
  .hint {
    .bp4-popover-wrapper {
      display: inline;
    }
  }

  &:not(.bp4-inline) .hint .bp4-popover-target {
    display: inline;
    margin-left: 0;
  }
}

.bp4-popover.bp4-tooltip {
  max-width: 300px;
}

.bp4-timezone-picker {
  .bp4-button {
    [icon='caret-down'] {
      display: none;
    }
  }
}

.bp4-progress-bar.bp4-intent-primary .bp4-progress-meter {
  background-color: #0066ff;
}

.ReactVirtualized__Collection {}

.ReactVirtualized__Collection__innerScrollContainer {}

/* Grid default theme */

.ReactVirtualized__Grid {}

.ReactVirtualized__Grid__innerScrollContainer {}

/* Table default theme */

.ReactVirtualized__Table {}

.ReactVirtualized__Table__Grid {}

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ReactVirtualized__Table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}

.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}

.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: flex;
  align-items: center;
}

.ReactVirtualized__Table__sortableHeaderIcon {
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}

.ReactVirtualized__Grid,
.ReactVirtualized__List {
  direction: inherit !important;
}

/* List default theme */

.ReactVirtualized__List {}

.bp4-drawer {
  box-shadow: 0 0 0;
  background-color: #fbfbfb;

  .dashboard__loading-indicator {
    margin: auto;
  }
}

// RTL Icons.
html[dir='rtl'] {
  .bp4-icon-caret-right {
    transform: scaleX(-1);
  }
}

html[lang^='ar'] {
  body {
    letter-spacing: -0.01rem;
  }
}

.bp4-popover2 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.02), 0 2px 4px rgba(16, 22, 26, 0.1),
    0 8px 24px rgba(16, 22, 26, 0.1);
}

.bp4-tooltip2 .bp4-popover2-arrow:before {
  box-shadow: 0 0 0;
}

.dialog--pdf-preview-dialog {
  width: 1000px;
  max-height: 800px;
  margin: 10px 0;
  overflow: hidden;
  align-self: flex-start;
  padding-bottom: 0;
  position: relative;

  .dialog__header-actions {
    position: absolute;
    right: 50px;
    top: 0;
    z-index: 9999999;
    margin: 6px;

    .bp4-button {
      border-color: rgba(0, 0, 0, 0.25);
      color: rgb(25, 32, 37);
      min-height: 30px;
      padding-left: 14px;
      padding-right: 14px;

      &+.bp4-button {
        margin-left: 8px;
      }
    }
  }

  .bp4-dialog {
    &-body {
      &:not(.loading) {
        margin: 0;
      }

      >.bp4-spinner {
        margin: 20px 0;
      }
    }
  }
}

.bp4-drawer {
  border-left: 1px solid #00115e;
}

.drawer-portal {
  .bp4-overlay-backdrop {
    background: rgba(0, 10, 30, 0.05);
  }
}

.big-number {
  font-size: 28px;
  color: #c06361;
  margin: 6px 0;
  font-weight: 600;
  margin-bottom: 0;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.font-bold {
  font-weight: 600;
}


span.table-tooltip-overview-target {
  display: inline;
}

.bp4-callout .bp4-heading:first-child {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
}